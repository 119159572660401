<template>
	<ej-page>
		<ej-mobile-header>
			<ej-toolbar>
				<ej-buttons>
					<ej-back-button></ej-back-button>
				</ej-buttons>

				<ej-title>{{ $route.meta.title }}</ej-title>

				<ej-buttons style="margin-left: auto">
					<ej-button
						@click="sortingToggle"
						:class="{ active: sortingOpened, 'mr-1': true }"
						v-if="_.get($store.state, `${module}.sorting.enabled`)"
					>
						<font-awesome-icon :icon="['fas', 'sort']"></font-awesome-icon>
					</ej-button>

					<ej-button
						@click="filteringToggle"
						:class="{ active: filteringOpened }"
						v-if="_.get($store.state, `${module}.filtering.enabled`)"
					>
						<font-awesome-icon :icon="['fas', 'filter']"></font-awesome-icon>
					</ej-button>
				</ej-buttons>
			</ej-toolbar>
		</ej-mobile-header>

		<ej-content>
			<ej-breadcrumb>
				<li class="breadcrumb-item">
					{{ $route.meta.title }}
				</li>
			</ej-breadcrumb>

			<div class="header">
				<div class="container-fluid">
					<div class="row">
						<div class="col-lg-7">
							<div :class="{ row: true, 'mobile-hidden': filteringMobileHidden }">
								<h4 class="col-12">Filtros</h4>

								<div class="form-group col-lg-4 col-12">
									<label>Campo:</label>
									<multiselect
										v-model="filteringField"
										:options="$store.state[module].filtering.fields"
										:searchable="true"
										:close-on-select="true"
										:show-labels="false"
										placeholder=""
										track-by="value"
										label="name"
									></multiselect>
								</div>

								<div class="form-group col-lg-4 col-12">
									<label>Critério:</label>
									<multiselect
										v-model="filteringCriteria"
										:options="$store.state[module].filtering.criterias"
										:searchable="true"
										:close-on-select="true"
										:show-labels="false"
										placeholder=""
										track-by="value"
										label="name"
									></multiselect>
								</div>

								<div class="form-group col-lg-4 col-12">
									<label>Pesquisar por:</label>
									<input type="text" class="form-control" v-model="filteringKeyword">
								</div>
							</div>
						</div>

						<div class="col-lg-5">
							<div :class="{ row: true, 'mobile-hidden': sortingMobileHidden }">
								<h4 class="col-12">Ordenação</h4>

								<div class="form-group col-lg-6 col-12">
									<label>Campo:</label>
									<multiselect
										v-model="sortingField"
										:options="$store.state[module].sorting.fields"
										:searchable="true"
										:close-on-select="true"
										:show-labels="false"
										placeholder=""
										track-by="value"
										label="name"
									></multiselect>
								</div>

								<div class="form-group col-lg-6 col-12">
									<label>Ordem:</label>
									<multiselect
										v-model="sortingDirection"
										:options="$store.state[module].sorting.directions"
										:searchable="true"
										:close-on-select="true"
										:show-labels="false"
										placeholder=""
										track-by="value"
										label="name"
									></multiselect>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<main class="content">
				<div class="d-flex justify-content-center" v-if="$store.state.users.fetching">
					<div class="default-spinner">
						<div class="default-spinner-inner">
							<div></div>
							<div></div>
						</div>
					</div>
				</div>
				<div v-else>
					<div class="container-fluid">
						<div class="row">
							<div class="col">
								<table class="desktop-list-content">
									<thead>
										<tr>
											<th class="sortable" @click="setOrder('people.id')">
												Id <ej-order-icon :module="module" :field="'people.id'"></ej-order-icon>
											</th>
											<th class="sortable" @click="setOrder('people.name')">
												Nome <ej-order-icon :module="module" :field="'people.name'"></ej-order-icon>
											</th>
											<th class="sortable" @click="setOrder('people.email')">
												E-mail <ej-order-icon :module="module" :field="'people.email'"></ej-order-icon>
											</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr :key="index" v-for="(user, index) in $store.state.users.users">
											<td>{{ user.id }}</td>
											<td>{{ user.name }}</td>
											<td>{{ user.email }}</td>
											<td class="actions">
												<button class="btn btn-primary" v-tooltip="'Solicitar redefinição de senha por e-mail'" @click="sendResetLinkEmail(user.email)">
													<font-awesome-icon :icon="['fas', 'sync-alt']"></font-awesome-icon>
												</button>
												<router-link class="btn btn-primary mr-1" v-tooltip="'Editar'" :to="{name: 'people.edit', params: {id: user.id}}">
													<font-awesome-icon :icon="['far', 'edit']"></font-awesome-icon>
												</router-link>
												<button class="btn btn-primary" v-tooltip="'Excluir'" @click="deleteUser(user.id, index)">
													<font-awesome-icon :icon="['far', 'trash-alt']"></font-awesome-icon>
												</button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>

						<div class="mobile-list-content">
							<div class="row">
								<div class="col-12 col-md-6" :key="index" v-for="(user, index) in $store.state.users.users">
									<div class="card">
										<div class="card-body">
											<h5 class="card-title"><strong>{{ user.id }}</strong> - {{ user.name }}</h5>
											<p class="card-text"><strong>E-mail:</strong> {{ user.email }}</p>

											<div class="actions">
												<button class="btn btn-primary" v-tooltip="'Solicitar redefinição de senha por e-mail'" @click="sendResetLinkEmail(user.email)">
													<font-awesome-icon :icon="['fas', 'sync-alt']"></font-awesome-icon>
												</button>
												<router-link class="btn btn-primary" v-tooltip="'Editar'" :to="{name: 'people.edit', params: {id: user.id}}">
													<font-awesome-icon :icon="['far', 'edit']"></font-awesome-icon>
												</router-link>
												<router-link class="btn btn-primary" v-tooltip="'Excluir'" :to="{name: 'people.delete', params: {id: user.id}}">
													<font-awesome-icon :icon="['far', 'trash-alt']"></font-awesome-icon>
												</router-link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="container-fluid">
						<div class="row">
							<div class="col d-flex justify-content-center">
								<nav class="pagination-nav" aria-label="Page navigation example">
									<ul class="pagination">
										<li class="page-item" v-if="$store.state.users.pagination.current_page > 1">
											<button class="page-link" @click="setPage($store.state.users.pagination.current_page-1)">Anterior</button>
										</li>
										<li :class="{ 'page-item':true, active: page === $store.state.users.pagination.current_page}" :key="index" v-for="(page, index) in $store.state.users.pagination.last_page">
											<button class="page-link" @click="setPage(page)">{{ page }}</button>
										</li>
										<li class="page-item" v-if="$store.state.users.pagination.current_page < $store.state.users.pagination.last_page">
											<button class="page-link" @click="setPage($store.state.users.pagination.current_page+1)">Próxima</button>
										</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
				</div>
			</main>

			<ej-floating-button @click="$router.push({name: 'people.create'})">
				<font-awesome-icon :icon="['fas', 'plus']"></font-awesome-icon>
			</ej-floating-button>
		</ej-content>
	</ej-page>
</template>

<script>
	//Components
	import Multiselect from 'vue-multiselect';

	//Mixins
	import SortingMixin from "@/mixins/SortingMixin";
	import FilteringMixin from "@/mixins/FilteringMixin";
	import PaginationMixin from "@/mixins/PaginationMixin";
	import DateMixin from "@/mixins/DateMixin";
	import FetchingMixin from "@/mixins/FetchingMixin";
	import CurrencyMixin from "@/mixins/CurrencyMixin";

	//Aux
	import {customWatch, exibeErro} from "@/helpers";
	import Swal from "sweetalert2";

	export default {
		data() {
			return {
				module: 'users'
			}
		},

		mixins: [
			FilteringMixin,
			SortingMixin,
			PaginationMixin,
			DateMixin,
			FetchingMixin,
			CurrencyMixin
		],

		watch: {
			"$store.state.users.sorting.selected": customWatch,
			"$store.state.users.filtering.selected": customWatch,
			"$store.state.users.filtering.keyword": customWatch,
			"$store.state.users.pagination.current_page": customWatch,
		},

		methods: {
			sendResetLinkEmail(e) {
				const vm = this;

				Swal.fire({
					title: 'Confirmação',
					html: 'Você tem certeza que deseja solicitar a redefinição de senha desse usuário?',
					icon: 'warning',
					confirmButtonText: 'Sim, solicitar',
					showCancelButton: true,
					showLoaderOnConfirm: true,
					preConfirm: async function() {
						try {
							await vm.$store.dispatch(`passwords/request_reset`, {email: e});
						} catch (e) {
							exibeErro(e);
						}
					},
					allowOutsideClick: () => !Swal.isLoading()
				})
					.then(function(result) {
						if (result.isConfirmed) {
							Swal.fire(
								'Sucesso!',
								'Solicitação enviada com sucesso, verifique seu e-mail!',
								'success'
							);
						}
					})
					.catch(function (error) {
						// handle error
						exibeErro(error);
					})
					.then(function () {
						// always executed
					});
			},

			deleteUser(id, index) {
				const vm = this;

				Swal.fire({
					title: 'Confirmação',
					html: 'Você tem certeza que deseja excluir esse usuário?<small>Obs.: Esta pessoa só deixará de ser um usuário. Ela continuará aparecendo no cadastro de pessoas.',
					icon: 'warning',
					confirmButtonText: 'Sim, excluir',
					confirmButtonColor: '#d33',
					showCancelButton: true,
				}).then(function(result) {
					if (result.isConfirmed)
						vm.$store.dispatch(`users/delete`, id)
							.then(function() {
								Swal.fire(
									'Sucesso!',
									'Usuário deletado com sucesso!',
									'success'
								).then(() => {
									//Se esse for o usuário logado, desloga ele
									if (id == vm.$store.state.auth.user.id)
										vm.$store.dispatch(`auth/logout`);

									vm.$delete(vm.$store.state.users.users, index);
								});
							})
							.catch(function (error) {
								// handle error
								exibeErro(error);
							})
							.then(function () {
								// always executed
							});
				});
			}
		},

		mounted() {
			//Fetch inicial
			this.requestData();
		},

		components: {
			Multiselect,
		},
	}
</script>

<style scoped>

</style>
